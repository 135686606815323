export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // $('.language__switcher__current').hover(function () {
        //     $(this).parent().toggleClass('open')
        // })

        // Slick slider example
        $('.slider__layer__slider__inner').slick({
            dots: false,
            arrows: true,
            infinite: true,
            speed: 300,
            fade: true,
            autoplay: true,
            autoplaySpeed: 2000,
            nextArrow: '.next__arrow',
            prevArrow: '.prev__arrow',
            asNavFor: '.slider__layer__slider__navigation',
        });

        $('.slider__layer__slider__navigation').slick({
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            focusOnSelect: true,
            asNavFor: '.slider__layer__slider__inner',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

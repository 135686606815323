import {WOW} from 'wowjs';

export default {
    init() {
        /* eslint-disable */

        window.CI360.init();

        const wow = new WOW();

        wow.init();
        (function($) {

            /* Wait for width calculation on slider load */
            setTimeout(function(){
                $('input[type="range"]').rangeslider({polyfill: false});
            }, 100);

            /* Defender image and type change on slide */
            var defendercontainer = document.getElementById('defendercontainer');
            var sliderspeed = document.getElementById('speedrange');
            var sliderheavyness = document.getElementById('heavynessrange');
            var outputimage = document.getElementById('typedefender');
            var outputnumber = document.getElementById('typedefendernumber');
            var defender800 = document.getElementById('defender800info');
            var defender1500 = document.getElementById('defender1500info');
            var defender3000 = document.getElementById('defender3000info');

            function getheight() {
                let showHeight = $('.show__defender').height();
                // console.log(showHeight);
                defendercontainer.style.height = showHeight+'px';
            }

            $(window).on('load resize',function(e){
                getheight();
            });

            function change800() {
                outputnumber.innerHTML = '800';
                outputimage.src = '/app/themes/sage/resources/assets/images/800.png';
            }
            function change1500() {
                outputnumber.innerHTML = '1500';
                outputimage.src = '/app/themes/sage/resources/assets/images/1500.png';
            }
            function change3000() {
                outputnumber.innerHTML = '3000';
                outputimage.src = '/app/themes/sage/resources/assets/images/3000.png';
            }
            function show800(){
                defender800.classList.add('show__defender');
                defender800.classList.remove('hide__defender');
            }
            function show1500() {
                defender1500.classList.add('show__defender');
                defender1500.classList.remove('hide__defender');
            }
            function show3000() {
                defender3000.classList.add('show__defender');
                defender3000.classList.remove('hide__defender');
            }
            function hide800(){
                defender800.classList.add('hide__defender');
                defender800.classList.remove('show__defender');
            }
            function hide1500() {
                defender1500.classList.add('hide__defender');
                defender1500.classList.remove('show__defender');
            }
            function hide3000() {
                defender3000.classList.add('hide__defender');
                defender3000.classList.remove('show__defender');
            }

            sliderspeed.oninput = function() {
                calculateDefenderAdvice();
                // if (this.value == 1) {
                //     change800(), show800(), hide1500(), hide3000();
                //     getheight()
                // } else if (this.value == 2) {
                //     change800(), show800(), hide1500(), hide3000();
                //     getheight()
                // } else if (this.value == 3) {
                //     change1500(), hide800(), show1500(), hide3000();
                //     getheight()
                // } else if (this.value == 4) {
                //     change1500(), hide800(), show1500(), hide3000();
                //     getheight()
                // } else if (this.value == 5) {
                //     change3000(), hide800(), hide1500(), show3000();
                //     getheight()
                // }
            };

            sliderheavyness.oninput = function() {
                calculateDefenderAdvice();
                // if (this.value == 0) {
                //     change800(), show800(), hide1500(), hide3000();
                //     getheight()
                // } else if (this.value == 10) {
                //     change1500(), hide800(), show1500(), hide3000();
                //     getheight()
                // } else if (this.value == 20) {
                //     change3000(), hide800(), hide1500(), show3000();
                //     getheight()
                // } else if (this.value == 30) {
                //     change3000(), hide800(), hide1500(), show3000();
                //     getheight()
                // }
            };

            function calculateDefenderAdvice() {
                let weight         = parseFloat(sliderheavyness.value) * 1000;
                let speed          = parseFloat(sliderspeed.value);
                let neededDefender = weight * 0.5 * (speed/3.6) * (speed/3.6);

                //console.debug({weight: weight, speed: speed, neededDefender: neededDefender});

                $("#input_4_10").val(speed);
                $("#input_4_11").val(weight);

                hide3000();
                hide1500();
                hide800();
                if (neededDefender > 1500) {
                    change3000();
                    show3000();
                    $("#input_4_12").val('3000');
                } else if (neededDefender > 800) {
                    // show 1500
                    change1500();
                    show1500();
                    $("#input_4_12").val('1500');
                } else {
                    // show 800
                    change800();
                    show800();
                    $("#input_4_12").val('800');
                }
            }

            var rackInput = document.getElementById('racks-input');
            var rackImage = document.getElementById('racks-image');

            rackInput.oninput = function() {
                if (this.value >= 0 && this.value <= 999) {
                    rackImage.src = '/app/themes/sage/resources/assets/images/rack1.jpg';
                } else if (this.value >= 1000 && this.value <= 1999) {
                    rackImage.src = '/app/themes/sage/resources/assets/images/rack2.jpg';
                } else if (this.value >= 2000 && this.value <= 2999) {
                    rackImage.src = '/app/themes/sage/resources/assets/images/rack3.jpg';
                } else if (this.value >= 3000 && this.value <= 3999) {
                    rackImage.src = '/app/themes/sage/resources/assets/images/rack4.jpg';
                } else if (this.value >= 4000) {
                    rackImage.src = '/app/themes/sage/resources/assets/images/rack5.jpg';
                }

                var accident = '';

                if(this.value == 0) {
                    $('#accident').text('2');
                    var accident = '2';
                } else {
                    $('#accident').text(Math.round(this.value / 250));
                    var accident = Math.round(this.value / 250);
                }

                $("#input_4_9").val(this.value);
                $("#input_4_13").val(accident);
            };

        })(jQuery);
    },
    finalize() {
    },
};
